/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@styles/style.scss"

// Fix vh units on mobile browsers
let vh = window.innerHeight * 0.01
let vhMenu = window.innerHeight * 0.01
let resizeTimer

document.documentElement.style.setProperty("--vh", "".concat(vh, "px"))
document.documentElement.style.setProperty("--vhMenu", "".concat(vhMenu, "px"))

window.addEventListener("resize", () => {
  // Clear debouncer
  clearTimeout(resizeTimer)

  // Debounce for better performance
  resizeTimer = setTimeout(() => {
    let vhMenu = window.innerHeight * 0.01
    document.documentElement.style.setProperty(
      "--vhMenu",
      "".concat(vhMenu, "px")
    )
    if (window.innerWidth >= 769) {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", "".concat(vh, "px"))
    }
  }, 300)
})
